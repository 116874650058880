import { useState, useEffect } from 'react';

// hardcoded value for the initial state
let globalOpen = false;

// let's store the functions to update the state for each interested component
// this is an array of setCounter's function pointers.
let listeners = [];

const useMenuChange = () => {
  // we're not interested in using the counter state, just setCounter
  const setOpen = useState(globalOpen)[1];

  // let's create a function that updates the global counter
  const change = () => {
    // let's change the variable globalCounter defined outside the Hook
    globalOpen = !globalOpen;

    // let's call the function setCounter corresponding to each interested component
    // so they re-render, and show the latest globalCounter value
    for (const listener of listeners) {
      listener(globalOpen);
    }
  };

  // let's register the components when they call this hook for the first time by
  // pushing their corresponding setCounter function into the listeners array
  useEffect(() => {
    //when the component did mount, its corresponding setCounter function is added to the list, as a pointer
    listeners.push(setOpen);

    //There's an unmounting clean up function (which is a closure), that de-registers the component from the listeners
    return () => {
      listeners = listeners.filter((li) => li !== setOpen);
    };
  }, [setOpen]);

  // let's return the global state and a way to update it
  return [globalOpen, change];
};

export default useMenuChange;